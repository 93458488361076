import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { MyImage } from '@/components/common/MyImage';

import Footer from '../components/common/Footer';
import { Article, RevArticle } from '../components/pages/Service/Article';
import ServiceHeader from '../components/pages/Service/ServiceHeader';

const Service = () => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <ServiceHeader />

      <Article
        bg_img={<MyImage src="/service/blueprint.jpg" />}
        heading="Manufacturing"
        content="Since establishing a local subsidiary in India in 1996, we have been engaged in various projects, both Japanese and non-Japanese, public and private, and have been engaged in the construction business, including design and construction management of building works, quality surveys, and installation of solar panels, as well as local production of necessary building materials at our own factory."
        heading2="【Feature1】Technical guidance by Japanese engineers"
        content2="Japan's high standard of construction quality and technology is very superior worldwide. We provide technical guidance on design and construction in India on a two-person basis, together with engineers who have experience in Japan."
        heading3="【Feature2】 Construction site management in India"
        content3="We utilize our total management capabilities, from planning and design of construction materials to commercialization and delivery, to procure and deliver necessary materials from Japan and overseas to reduce the burden on local craftspeople."
        heading4="【Feature3】 Introduction of remote guidance"
        content4="In the wake of the Corona disaster, there were restrictions on the travel between Japanese technicians and local craftsmen, and Collaboration OK, a system that allows remote supervision and guidance to Indian sites from Japan, was used to carry out 10 construction projects in 2021."
      />

      <RevArticle
        bg_img={<MyImage src="/service/education.png" />}
        heading="Human Resources Development"
        content={
          'Since establishing a local subsidiary in India in 1996, we have been engaged in various projects, both Japanese and non-Japanese, public and private, and have been engaged in the construction business, including design and construction management of building works, quality surveys, and installation of solar panels, as well as local production of necessary building materials at our own factory.'
        }
      />

      <Article
        bg_img={<MyImage src="/service/construction.jpg" />}
        heading="Overseas Construction ProjectsRemote technical support (design and survey)"
        content="In addition to the experience, know-how, and network that we have cultivated over 25 years of actual business in India, we make maximum use of our own local factories and construction sites, and provide all the support necessary for expansion into India, including market research, test sales, holding exhibitions, developing local partners, and human resources training."
      />

      <RevArticle
        bg_img={<MyImage src="/service/import.webp" />}
        heading="Import and export of
construction materials"
        content="We procure and deliver necessary materials from both domestic and overseas markets, utilizing our total management capabilities from planning and design to commercialization and delivery of construction materials. We also provide technical guidance and quality inspections for overseas construction projects using remote systems."
      />

      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  background-color: #204e73;
  div {
    color: white;
  }
`;

export default Service;
