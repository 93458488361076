import styled from '@emotion/styled';
import React from 'react';

import FlexRow from '../../layout/FlexRow';
import Paragraph, { ParagraphWithBoder } from './Paragraph';

export interface ArticleProps {
  bg_img?: React.ReactNode;
  bg_subimg1?: React.ReactNode;
  bg_subimg2?: React.ReactNode;
  heading: string;
  heading2?: string;
  heading3?: string;
  heading4?: string;
  content?: string;
  content2?: string;
  content3?: string;
  content4?: string;
  contentboder?: string;
}

export const Article = (props: ArticleProps) => {
  const { bg_img, bg_subimg1, bg_subimg2 } = props;
  return (
    <Container>
      <Avatar>
        {bg_img}
        <FlexRow>
          <Avatar>{bg_subimg1}</Avatar>
          <Avatar>{bg_subimg2}</Avatar>
        </FlexRow>
      </Avatar>
      <Paragraph
        heading={props.heading}
        content={props.content}
        heading2={props.heading2}
        content2={props.content2}
        heading3={props.heading3}
        content3={props.content3}
        heading4={props.heading4}
        content4={props.content4}
      />
    </Container>
  );
};

export const RevArticle = (props: ArticleProps) => {
  const { bg_img } = props;
  return (
    <RevContainer>
      <Paragraph heading={props.heading} content={props.content} />
      <Avatar>{bg_img}</Avatar>
    </RevContainer>
  );
};
export const RevBoderArticle = (props: ArticleProps) => {
  const { bg_img, bg_subimg1, bg_subimg2 } = props;
  return (
    <RevContainer>
      <ParagraphWithBoder
        heading={props.heading}
        content={props.content}
        heading2={props.heading2}
        contentboder={props.contentboder}
      />
      <Avatar>
        {bg_img}
        <FlexRow>
          <Avatar>{bg_subimg1}</Avatar>
          <Avatar>{bg_subimg2}</Avatar>
        </FlexRow>
      </Avatar>
    </RevContainer>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  padding-top: 10vh;
  align-items: flex-start;
  flex-direction: row;
  //  flex-wrap: nowrap;
  white-space: pre-wrap;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const RevContainer = styled.section`
  display: flex;
  padding-top: 15vh;
  max-width: 100vw;
  align-items: flex-start;
  flex-direction: row;
  //flex-wrap: nowrap;
  white-space: pre-wrap;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

const Avatar = styled.div`
  flex: 50%;
  position: relative;
  width: 100%;
  max-width: 50vw;
  padding-bottom: 5vh;
  @media (max-width: 800px) {
    max-width: 100vw;
  }
  height: auto;
`;
