import styled from '@emotion/styled';
import React from 'react';

import { MyImage } from '@/components/common/MyImage';

const ServiceHeader = () => {
  return (
    <>
      <Wrapper>
        <Banner>
          <MyImage src="/service/service_top.webp" />
          <Heading>
            S<Underline>ERVIC</Underline>E
          </Heading>
        </Banner>
      </Wrapper>
      <SubHeading></SubHeading>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Banner = styled.div`
  width: 100vw;
  height: 70vh;
  position: relative;
`;
const Heading = styled.div`
  position: relative;
  top: 95%;
  text-align: center;
  font-size: 4.1rem;
  font-weight: 700;
  color: #f2f2f2;
`;
const Underline = styled.span`
  padding-bottom: 20px;
  border-bottom: 10px solid #d9d9d9;
`;
const SubHeading = styled.div`
  margin-top: 6.2rem;
  margin-bottom: 6rem;
  line-height: 2.5rem;
  word-wrap: break-word;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
`;

export default ServiceHeader;
