import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import Spacer from '../../layout/Spacer';
import { ArticleProps } from './Article';

const ParagraphWrapper = styled.div`
  flex: 50%;
  width: 100%;
  margin: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 5vh;
`;

export default function Paragraph(props: ArticleProps) {
  return (
    <>
      <ParagraphWrapper>
        <h2
          css={css`
            font-size: 2rem;
            font-weight: 600;
          `}
        >
          {props.heading}
        </h2>
        <br />
        <p
          css={css`
            font-size: 1.1rem;
            font-weight: 100;
            line-height: 40px;
          `}
        >
          {props.content}
        </p>
        <br />
        <br />
        <h2
          css={css`
            font-size: 1.4rem;
            font-weight: 500;
          `}
        >
          {props.heading2}
        </h2>
        <br />
        <p
          css={css`
            font-size: 1.1rem;
            font-weight: 100;
            line-height: 40px;
          `}
        >
          {props.content2}
        </p>
        <br />
        <br />
        <h2
          css={css`
            font-size: 1.4rem;
            font-weight: 500;
          `}
        >
          {props.heading3}
        </h2>
        <br />
        <p
          css={css`
            font-size: 1.1rem;
            font-weight: 100;
            line-height: 40px;
          `}
        >
          {props.content3}
        </p>
        <br />
        <br />
        <h2
          css={css`
            font-size: 1.4rem;
            font-weight: 500;
          `}
        >
          {props.heading4}
        </h2>
        <br />
        <p
          css={css`
            font-size: 1.1rem;
            font-weight: 100;
            line-height: 40px;
          `}
        >
          {props.content4}
        </p>
      </ParagraphWrapper>
    </>
  );
}

export function ParagraphWithBoder(props: any) {
  return (
    <>
      <ParagraphWrapper>
        <h2
          css={css`
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 16px;
          `}
        >
          {props.heading}
        </h2>
        <br />
        <p
          css={css`
            font-size: 1.1rem;
            font-weight: 100;
            line-height: 40px;
            letter-spacing: 4px;
          `}
        >
          {props.content}
        </p>
        <br />

        <p
          css={css`
            font-size: 1.1rem;
            font-weight: 100;
            padding: 3rem;
            line-height: 40px;
            letter-spacing: 4px;
            border-color: white;
            border-width: 2px;
            border-style: solid;
          `}
        >
          {props.contentboder}
        </p>
        <Spacer height="min(7.153vw, 103px)" />
      </ParagraphWrapper>
    </>
  );
}
